import { call } from "redux-saga/effects";
import { httpRequestForData, axiosApiRequestForMultiPartData } from "../api/api";
import apiInfo from "../api/apiEndPoints";
import actions from "../actionTypes";

export function* getDaysOfWeeks() {
  const {
    getDayOfWeekList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.SITE_DAYS_OF_WEEK_SUCCESS,
    errorAction: actions.SITE_DAYS_OF_WEEK_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addSiteGeneralDetails({ payload }) {
  const {
    addSiteDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.ADD_SITE_GENERAL_DETAILS_SUCCESS,
    errorAction: actions.ADD_SITE_GENERAL_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getDefaultShiftType() {
  const {
    getShiftType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.SITE_DEFAULT_SHIFT_TYPE_SUCCESS,
    errorAction: actions.SITE_DEFAULT_SHIFT_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addSiteContactDetails({ payload }) {
  const {
    addSiteContact: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?objectId=${payload.ObjectId}`,
    apiMethod: method,
    apiPayload: payload.payload,
    successAction: actions.ADD_SITE_CONTACT_FORM_SUCCESS,
    errorAction: actions.ADD_SITE_CONTACT_FORM_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getSiteDocumentType() {
  const {
    getSiteDocumentType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.SITE_DOCUMENT_SUCCESS,
    errorAction: actions.SITE_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addSiteDocument({ payload }) {
  const {
    addSiteDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?siteObjectId=${payload.ObjectId}&requestObject=${payload.params}`,
    apiMethod: method,
    apiPayload: payload.payload,
    successAction: actions.ADD_SITE_DOCUMENT_SUCCESS,
    errorAction: actions.ADD_SITE_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  if (payload.payload.has("siteDocumentFiles") || payload.payload.has("generalFiles")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getSupplierList() {
  const {
    getSupplierrList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.SITE_SUPPLIER_LIST_SUCCESS,
    errorAction: actions.SITE_SUPPLIER_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* saveAwrDetails({ payload }) {
  const {
    saveAwrDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: `${endPoint}${payload.ObjectId}`,
    apiMethod: method,
    apiPayload: payload.payloadData,
    successAction: actions.SAVE_AWR_DETAILS_SUCCESS,
    errorAction: actions.SAVE_AWR_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* requirementList({ payload }) {
  const { endPoint, method, successMessage, errorMessage } = payload;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    successAction: actions.BOOKING_RULE_REQUIREMENTS_TYPE_SUCCESS,
    errorAction: actions.BOOKING_RULE_REQUIREMENTS_TYPE_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* saveBookingRule({ payload }) {
  const {
    saveBookingRule: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: `${endPoint}${payload.ObjectId}`,
    apiMethod: method,
    apiPayload: payload.payloadData,
    successAction: actions.SAVE_BOOKING_RULE_SUCCESS,
    errorAction: actions.SAVE_BOOKING_RULE_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* defaultAWRRule({ payload }) {
  const {
    defaultAWRDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: `${endPoint}${payload}`,
    apiMethod: method,
    successAction: actions.DEFAULT_AWR_RULE_SUCCESS,
    errorAction: actions.DEFAULT_AWR_RULE_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* updateSiteContact({ payload }) {
  const {
    updateContact: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: `${endPoint}${payload.ObjectId}`,
    apiMethod: method,
    apiPayload: payload.formValues,
    successAction: actions.UPDATE_SITE_CONTACT_SUCCESS,
    errorAction: actions.UPDATE_SITE_CONTACT_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* getSiteDetails({ payload }) {
  const {
    getGeneralDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload}`,
    apiMethod: method,
    successAction: actions.GET_SITE_DETAILS_SUCCESS,
    errorAction: actions.GET_SITE_DETAILS_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* getSiteContactDetails({ payload }) {
  const {
    getContactDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload}`,
    apiMethod: method,
    successAction: actions.GET_SITE_CONTACT_DETAILS_SUCCESS,
    errorAction: actions.GET_SITE_CONTACT_DETAILS_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* updateGeneralDetails({ payload }) {
  const {
    updateSiteGeneralDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.UPDATE_GENERAL_DETAILS_SUCCESS,
    errorAction: actions.UPDATE_GENERAL_DETAILS_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* addClientShiftTypeData({ payload }) {
  const {
    addClientShiftTypeData: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.ADD_CLIENT_SHIFT_TYPE_DATA_SUCCESS,
    errorAction: actions.ADD_CLIENT_SHIFT_TYPE_DATA_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* handleApprovedSuppliers({ payload }) {
  const {
    siteApprovedSupplier: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.APPROVED_SUPPLIERS_SUCCESS,
    errorAction: actions.APPROVED_SUPPLIERS_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* updateClientShiftTypeData({ payload }) {
  const {
    updateClientShiftTypeDetail: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.UPDATE_CLIENT_SHIFT_TYPE_DATA_SUCCESS,
    errorAction: actions.UPDATE_CLIENT_SHIFT_TYPE_DATA_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* deleteClientShiftTypeData({ payload }) {
  const {
    deleteClientShiftTypeDetail: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: `${endPoint}?ClientShiftTypeDetailObjectId=${payload}`,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.DELETE_CLIENT_SHIFT_TYPE_DATA_SUCCESS,
    errorAction: actions.DELETE_CLIENT_SHIFT_TYPE_DATA_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* deleteAWRDetails({ payload }) {
  const {
    deleteAWRRule: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload}`,
    apiMethod: method,
    successAction: actions.DELETE_AWR_RULE_SUCCESS,
    errorAction: actions.DELETE_AWR_RULE_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* copyAWRDetails({ payload }) {
  const {
    copyAWRRule: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.COPY_AWR_RULE_SUCCESS,
    errorAction: actions.COPY_AWR_RULE_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* getBookingRule({ payload }) {
  const {
    getBookingRule: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload}`,
    apiMethod: method,
    successAction: actions.GET_BOOKING_RULE_SUCCESS,
    errorAction: actions.GET_BOOKING_RULE_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* saveResourceRequirements({ payload }) {
  const {
    saveResourceRequirements: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload?.ObjectId}`,
    apiMethod: method,
    apiPayload: payload?.data,
    successAction: actions.SAVE_RESOURCE_REQUIREMENT_SUCCESS,
    errorAction: actions.SAVE_RESOURCE_REQUIREMENT_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* getResourceRequirements({ payload }) {
  const {
    getResourceRequirements: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload}`,
    apiMethod: method,
    successAction: actions.GET_RESOURCE_REQUIREMENT_SUCCESS,
    errorAction: actions.GET_RESOURCE_REQUIREMENT_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* deleteResourceRequirements({ payload }) {
  const {
    deleteResourceRequirements: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload?.ObjectId}`,
    apiMethod: method,
    apiPayload: payload?.data,
    successAction: actions.DELETE_RESOURCE_REQUIREMENT_SUCCESS,
    errorAction: actions.DELETE_RESOURCE_REQUIREMENT_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* updateResourceRequirements({ payload }) {
  const {
    updateResourceRequirements: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload?.ObjectId}`,
    apiMethod: method,
    apiPayload: payload?.data,
    successAction: actions.UPDATE_RESOURCE_REQUIREMENT_SUCCESS,
    errorAction: actions.UPDATE_RESOURCE_REQUIREMENT_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* getSitePipelineCandidates({ payload }) {
  const {
    pipelineCandidate: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload}`,
    apiMethod: method,
    successAction: actions.GET_PIPELINE_CANDIDATE_SUCCESS,
    errorAction: actions.GET_PIPELINE_CANDIDATE_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* getSiteCandidatePerformance({ payload }) {
  const {
    candidatePerformance: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload}`,
    apiMethod: method,
    successAction: actions.GET_SITE_CANDIDATE_PERFORMANCE_SUCCESS,
    errorAction: actions.GET_SITE_CANDIDATE_PERFORMANCE_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* getSiteInfringement({ payload }) {
  const {
    siteinfringements: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload}`,
    apiMethod: method,
    successAction: actions.GET_SITE_INFRINGEMENT_SUCCESS,
    errorAction: actions.GET_SITE_INFRINGEMENT_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* getSiteDocument({ payload }) {
  const {
    getSiteDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload}`,
    apiMethod: method,
    successAction: actions.GET_SITE_DOCUMENT_SUCCESS,
    errorAction: actions.GET_SITE_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* deleteSiteDocument({ payload }) {
  const {
    deleteSiteDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload}`,
    apiMethod: method,
    successAction: actions.DELETE_SITE_DOCUMENT_SUCCESS,
    errorAction: actions.DELETE_SITE_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* updateSiteInfringementData({ payload }) {
  const {
    updateSiteInfringement: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload?.params}`,
    apiMethod: method,
    apiPayload: payload?.payload,
    successAction: actions.UPDATE_SITE_INFRINGEMENT_SUCCESS,
    errorAction: actions.UPDATE_SITE_INFRINGEMENT_ERROR,
    successMessage,
    errorMessage
  };
  if (payload.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getClientCandidateHistory({ payload }) {
  const {
    clientCandidateHistory: { endPoint, method, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.CANDIDATE_HISTORY_SUCCESS,
    errorAction: actions.CANDIDATE_HISTORY_ERROR,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* clientCandidateEmail({ payload }) {
  const {
    clientCandidateEmail: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.CANDIDATE_EMAIL_ALL_SUCCESS,
    errorAction: actions.CANDIDATE_EMAIL_ALL_ERROR,
    errorMessage,
    successMessage
  };
  yield call(httpRequestForData, requestParameters);
}
