import keyMirror from "keymirror";

const actions = {
  DRIVER_DETAILS_FORM: null,
  DRIVER_ADDRESS_DETAILS_FORM: null,
  DRIVER_ADDITIONAL_DETAILS_FORM: null,
  DRIVER_ATTACHMENT_DETAILS: null,
  DRIVER_LICENSE_DETAILS_FORM: null,
  DRIVER_QUALIFICATION_DETAILS_FORM: null,
  DRIVER_LICENCE_FORM: null,
  DRIVER_RESET_FORMS: null,
  DRIVER_PAYROLL_FORMS: null,
  DRIVER_PREFERENCE_FORMS: null,
  DRIVER_HUMAN_RESOURCE: null,
  DRIVER_RATINGS_FORMS: null,
  DRIVER_INFRINGEMENTS_FORMS: null,
  DRIVER_NOTES_FORMS: null,
  DRIVER_PERFORMANCE_FORMS: null,
  DRIVER_TASKS_FORMS: null,
  DRIVER_SMART_BOX_FORM: null
};

export default keyMirror(actions);
